import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, useParams } from "react-router-dom";
import { Layout } from "antd";
import "./App.less";
import "./App.scss";
import "./assets/scss/unimake-3/Unimake_3.scss";

import MenuNavigation from "./services/MenuNavigation";
import UmkMainNav from "./components/menu/UmkMainNav";
import ScrollTop from "./ScrollTop";
import UmkGoTop from "./components/unimake-gotop/UmkGoTop";
import UmkFooterV3 from "./components/v3/unimake-footer/UmkFooter";
import HomePage from "./pages/Home/HomePage";
import DownloadsPage from "./pages/Downloads/DownloadsPage";
import ProdutosHome from "./pages/Produtos/Home/ProdutosHome";
import BIPage from "./pages/Produtos/Produtos/BIPage";
import UniDANFEPage from "./pages/Produtos/Produtos/UniDANFEPage";
import ForcaVendasPage from "./pages/Produtos/Produtos/ForcaVendasPage";
import UnicoAutoPage from "./pages/Produtos/Produtos/UnicoAutoPage";
import UnicoFeculariaPage from "./pages/Produtos/Produtos/UnicoFeculariaPage";
import UnicoFerragensPage from "./pages/Produtos/Produtos/UnicoFerragensPage";
import UnicoMaterialConstrucaoPage from "./pages/Produtos/Produtos/UnicoMaterialConstrucaoPage";
import UnicoMaterialEletricoPage from "./pages/Produtos/Produtos/UnicoMaterialEletricoPage";
import CadastroClientePage from "./pages/ClienteArea/CadastroClientePage";
import LoginClientePage from "./pages/ClienteArea/LoginClientePage";
import EsqueciMinhaSenhaPageCliente from "./pages/ClienteArea/EsqueciMinhaSenhaClientePage";
import AlterarSenhaClientePage from "./pages/ClienteArea/AlterarSenhaClientePage";
import AlterarSenhaClienteAuthPage from "./pages/ClienteArea/AlterarSenhaClienteAuthPage";
import DashboardPage from "./pages/ClienteArea/DashboardClientePage";
import ProfileClientePage from "./pages/ClienteArea/ProfileClientePage";
import LicencaPage from "./pages/ClienteArea/LicencaPage";
import TrabalheConoscoPage from "./pages/TrabalheConosco/TrabalheConoscoPage";
import CurriculoPage from "./pages/TrabalheConosco/CurriculoPage";
import AlterarSenhaPage from "./pages/TrabalheConosco/AlterarSenhaPage";
import EsqueciMinhaSenhaPage from "./pages/TrabalheConosco/EsqueciMinhaSenhaPage";
import CarrinhoPage from "./pages/Loja/CarrinhoPage";
import FecharCompraPage from "./pages/Loja/FecharCompraPage";
import LGPDPage from "./pages/LGPD/LGPDPage";
import NotFoundPage from "./pages/NotFoundPage";
import AdicionarItemCarrinho from "./services/Loja/AdicionarItemCarrinho";

import ReactGA from 'react-ga4';

const TRACKING_ID = 'G-45QY2YELRT';
ReactGA.initialize(TRACKING_ID);

const { Header, Content } = Layout;

class App extends Component {
	private Menu: MenuNavigation;

	constructor(props: any) {
		super(props);

		this.Menu = new MenuNavigation(props);
	}

	render() {
		return (
			<Layout>
				<Router>

					<ScrollTop>
						<Header className="main-header">
							<UmkMainNav />
						</Header>

						<Content className="main-content-v3">
							<Switch>
								<Route exact path={`${process.env.PUBLIC_URL}/`} component={this.Menu.OpenPage(HomePage)} />
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/downloads`}
									component={this.Menu.OpenPage(DownloadsPage)}
								/>

								{/* Produtos */}
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/produtos`}
									component={this.Menu.OpenPage(ProdutosHome)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/produtos/bi`}
									component={this.Menu.OpenPage(BIPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/produtos/unidanfe`}
									component={this.Menu.OpenPage(UniDANFEPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/produtos/forcavendas`}
									component={this.Menu.OpenPage(ForcaVendasPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/produtos/unico-auto`}
									component={this.Menu.OpenPage(UnicoAutoPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/produtos/unico-fecularia`}
									component={this.Menu.OpenPage(UnicoFeculariaPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/produtos/unico-ferragens`}
									component={this.Menu.OpenPage(UnicoFerragensPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/produtos/unico-material-construcao`}
									component={this.Menu.OpenPage(UnicoMaterialConstrucaoPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/produtos/unico-material-eletrico`}
									component={this.Menu.OpenPage(UnicoMaterialEletricoPage)}
								/>

								{/* Cliente  */}
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/cliente/cadastro`}
									component={this.Menu.OpenPage(CadastroClientePage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/cliente/login`}
									component={this.Menu.OpenPage(LoginClientePage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/cliente/esqueci-minha-senha`}
									component={this.Menu.OpenPage(EsqueciMinhaSenhaPageCliente)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/cliente/alterar-senha`}
									component={this.Menu.OpenPage(AlterarSenhaClientePage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/cliente/alterar-senha-auth`}
									component={this.Menu.OpenPage(AlterarSenhaClienteAuthPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/cliente/dashboard`}
									component={this.Menu.OpenPage(DashboardPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/cliente/perfil`}
									component={this.Menu.OpenPage(ProfileClientePage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/cliente/licenca/editar`}
									component={this.Menu.OpenPage(LicencaPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/cliente/licenca/renovar`}
									component={this.Menu.OpenPage(LicencaPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/cliente/licenca/desativar`}
									component={this.Menu.OpenPage(LicencaPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/cliente/licenca/reativar`}
									component={this.Menu.OpenPage(LicencaPage)}
								/>

								{/* Trabalhe Conosco */}
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/trabalhe-conosco`}
									component={this.Menu.OpenPage(TrabalheConoscoPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/trabalhe-conosco/curriculo`}
									component={this.Menu.OpenPage(CurriculoPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/trabalhe-conosco/alterar-senha`}
									component={this.Menu.OpenPage(AlterarSenhaPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/trabalhe-conosco/esqueci-minha-senha`}
									component={this.Menu.OpenPage(EsqueciMinhaSenhaPage)}
								/>

								{/* Loja */}
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/loja/carrinho`}
									component={this.Menu.OpenPage(CarrinhoPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/loja/fechar`}
									component={this.Menu.OpenPage(FecharCompraPage)}
								/>
								<Route
									exact
									path={`${process.env.PUBLIC_URL}/adicionar-ao-carrinho/:id/:quantidade`}
									component={AdicionarItemCarrinho}
								/>


								<Route
									exact
									path={`${process.env.PUBLIC_URL}/lgpd`}
									component={this.Menu.OpenPage(LGPDPage)}
								/>
								<Route path='*' component={this.Menu.OpenPage(NotFoundPage)} />
							</Switch>
						</Content>

						<UmkGoTop />
						<UmkFooterV3 />
					</ScrollTop>
				</Router>
			</Layout>
		);
	}
}

export default App;